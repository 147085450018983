<div class="text-center mat-bg-primary pt-1 pb-1">
  <h6 class="m-0">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngFor="let n of notificationList | slice:0:15; let i = index" (click)="readNotiification(n)"
    class="notific-item" role="listitem" routerLinkActive="open">
    <!-- <mat-icon class="notific-icon mr-1">notification_important</mat-icon> -->

    <img style="width: 28px;" [src]="n.image" alt="">
    <a>
      <div class="mat-list-text">
        <h4 class="message">{{n.title}}</h4>
        <small class="time text-muted">{{ n.created_on ? (n.created_on | timeago:live) : ''}}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
<!-- <div class="text-center mt-1" *ngIf="notifications?.length">
                  <small><a href="#" (click)="clearAll($event)">Clear all notifications</a></small>
                </div> -->

<div class="text-center mt-1" *ngIf=' notificationList?.length != 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px;">View More</a></small>
</div>
<div class="text-center mt-1" *ngIf=' notificationList?.length == 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px;">View List</a></small>
</div>