import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { decodedToken } from 'app/core/helpers/token.helper';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { BellCountService } from 'app/core/services/bell-count.service';
import { checkNull } from 'app/core/services/date-and-time-validators.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ToastService } from 'app/core/services/toast.service';
import { UserService } from 'app/core/services/user.service';
import { NotificationService } from 'app/modules/admin/notification/service/notification.service';
import { GTM } from 'app/modules/admin/task-manager/service/task-manager-constants';
import { NotificationsService } from 'app/modules/employee/notification/service/notification.service';
import { get } from 'lodash';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  providers: [MessagingService]
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Input() notificPanel;
  // Dummy notifications
  notifications
  portalType: any;
  notificationsList: any;
  employeeType: any;
  count: any;
  token: any;
  constructor(private router: Router,
    private modelService: NotificationService,
    private employeeService: NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,
    private userService: UserService,
    private countService: BellCountService

  ) {
    this.reloadNotify()
    this.removeNotificationBasedOnId();
    this.getCurrentNotification()
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.token = decodedToken();
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });

    this.userService.currentUser.subscribe((current: any) => {
      const token = window.localStorage.getItem('PRINTAPPADMIN');
      if (token) {
        this.messagingService.receiveMessage();
      }
    });


    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
    this.employeeType = get(token, 'employee_type') ?? '';
    this.getNotificationList()
    this.countService.clickedBell.subscribe((res: any) => {
      this.getNotificationList()
    })
  }



  reloadNotify() {

  }

  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        this.notifications.unshift(res);
      }
    })
  }

  removeNotificationBasedOnId() {

  }


  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

  getAllNotification() {

  }
  clicked(index, row) {

  }

  // Developed Functionalities

  notificationList: any[] = []
  getNotificationList() {
    if (decodedToken().portal == 'admin') {
      this.getAdminNotifications()
    } else {
      this.getEmployeeNotifications()
    }
  }

  refreshNotifactionList() {
    let msgCount = this.notificationList.filter((ele: any) => ele?.msg_read == 0)?.length ?? 0;
    this.countService.setBellCount(msgCount)
  }

  getAdminNotifications() {
    this.countService.notificationForAdminPanel().subscribe((res: GTM) => {
      this.loader.close();
      if (res.keyword == 'success') {
        this.notificationList = res?.data.filter((ele: any) => ele?.msg_read == 0) ?? []
        this.getSuitableImages()
        this.refreshNotifactionList()
      } else {
        this.notificationList = res?.data.filter((ele: any) => ele?.msg_read == 0) ?? [];
        this.getSuitableImages()
        this.refreshNotifactionList()
      }
    }, (err: any) => {
      this.toastr.showErrorToast('Something went wrong!');
      this.notificationList = []
    })
  }

  getSuitableImages() {
    let pages = ["order_cancelled", "order_page", "new_contest_post", "customer_preview", "attachment_approved", "order_cancel", "notify_me", "out_of_stock", "Order_cancel", "Order Place", "Mobile Register"]
    function setimages(ele: any) {
      switch (ele?.page) {
        case "order_page": return 'assets/images/notification/order.png';
        case "order_cancelled": return 'assets/images/notification/cancel1.png'
        case "Order_cancel": return 'assets/images/notification/cancel1.png'
        case 'order_delivered': return 'assets/images/notification/delivered.png'
        case 'order_ready_for_dispatch': return 'assets/images/notification/waiting.png'
        case 'order_dispatched': return 'assets/images/notification/dispatched.png'
        case 'attachment_approved': return 'assets/images/notification/approved.png'
        case 'attachment_rejection': return 'assets/images/notification/disapproved.png'
        case 'customer_preview': return 'assets/images/notification/preview.png'
        case 'new_contest_post': return 'assets/images/notification/bell.png'
        case 'notify_me': return 'assets/images/notification/notify.png'
        case 'new_product': return 'assets/images/notification/product_create.png';
        case 'out_of_stock': return 'assets/images/notification/outofstock.png';
        default: return 'assets/images/notification/bell.png'
      }
    }
    this.notificationList.map((ele: any) => {
      ele.image = setimages(ele)
    })
  }



  getEmployeeNotifications() {
    // this.loader.open()
    this.countService.notificationForEmployee().subscribe((res: GTM) => {
      this.loader.close();
      if (res.keyword == 'success') {
        this.notificationList = res?.data.filter((ele: any) => ele?.msg_read == 0) ?? []
        this.refreshNotifactionList()
      } else {
        this.notificationList = res?.data.filter((ele: any) => ele?.msg_read == 0) ?? []
        this.refreshNotifactionList()
      }
    }, (err: any) => {
      this.toastr.showErrorToast('Something went wrong!');
      this.notificationList = []
    })
  }

  viewMore() {
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.router.navigate(['/notification/list']);
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      this.router.navigate(['/employee/notification/list']);
    }

  }

  readNotiification(data: any) {

    if (data?.msg_read == 0) {
      let params = {
        notification_id: data?.notification_id,
        type: 'single'
      }
      this.loader.open()
      this.countService.readNotification(params, this.portalType).subscribe((res: any) => {
        this.loader.close();
        if (res?.keyword == 'success') {
          this.notificationList.map((ele: any) => {
            if (ele?.notification_id == data?.notification_id) {
              ele.msg_read = 1;
              this.refreshNotifactionList()
              this.routePage(data?.data)

            }
          })
        } else {
          this.toastr.showWarningToast(res?.message ?? 'Warning from API!')
        }
      }, (err: any) => {
        this.loader.close();
        // this.toastr.showErrorToast('Something went wrong!')
      })
    }
  }

  routePage(page: any) {
    let params = {};

    for (let data in page) {
      if (data != 'random_id') {
        params[data] = page[data];
      }
    }


    console.log(params);
    switch (page?.page) {
      // Admin
      case "order_page":
      case "order_cancel":
      case "order_ready_for_dispatch":
        {
          let url = (page?.url).split('?')[0];
          this.router.navigate([url], { queryParams: params });
        }
        break;
      case 'notify_me':
      case "out_of_stock":
        {
          let url = ``;
          switch (page?.service_id) {
            // Passport
            case 1:
              url = `passportsize-photo/view/${page?.product_id}`;
              break;
            //
            case 2:
              url = `photo-print/view/${page?.product_id}`;
              break;
            case 3:
              url = `Photoframe/view/${page?.product_id}`;
              break;
            case 4:
              url = `personalized-products/view/${page?.product_id}`;
              break;
            case 5:
              url = `e-commerce-product/view/${page?.product_id}`;
              break;
            case 6:
              url = `selfie-album/view/${page?.product_id}`;
              break;
          }
          this.router.navigate([url]);
        }
        break;
      // Employee
      case "task_assign":
      case "customer_preview_rejection":
      case "customer_preview_approved":
      case "qc_approved":
      case "qc_rejected": {

        let params = {
          taskManagerId: page?.task_manager_history_id ?? '',
          type: checkNull(page?.order_items_id) ? 'order' : 'task'
        }
        this.router.navigate([`employee/employee-task-manger/employee-task-detail`], { queryParams: params })

      }
        break
      default:
        {
          if (this.portalType == 'admin') {
            this.router.navigate([`notification/list`]);
          } else {
            this.router.navigate([`employee/notification/list`]);
          }

        }
        break;
    }
  }


}