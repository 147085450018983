export const environment = {
  production: false,
  apiURL: 'https://devprintappapi.technogenesis.in/api/V1/AP/', 
  //apiURL:`https://www.technogenesis.in/printappdevapi/api/V1/AP/`, //for developers
  EmpapiURL: "https://devprintappapi.technogenesis.in/api/V1/EP/",
  socketUrl: 'https://printappchat.technogenesis.in/',
  firebaseConfig: {
    apiKey: "AIzaSyA3waq5-59JJ_1mbfvA5MZCAuq2MDRCE6M",
    authDomain: "print-app-f9e62.firebaseapp.com",
    projectId: "print-app-f9e62",
    storageBucket: "print-app-f9e62.appspot.com",
    messagingSenderId: "948325265541",
    appId: "1:948325265541:web:6840db866f55f7058839ec",
    measurementId: "G-129C70PLHJ"
  },
};
