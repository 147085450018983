import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { checkNull } from 'app/core/services/date-and-time-validators.service';
import { debounceTime } from 'rxjs/operators';
import { SelectboxService } from '../selectbox.service';

@Component({
  selector: 'app-single-select-apioptions',
  templateUrl: './single-select-apioptions.component.html',
  styleUrls: ['./single-select-apioptions.component.css']
})
export class SingleSelectApioptionsComponent implements OnInit, OnChanges {
  //SEARCH INPUT PLACEHOLDER
  @Input('placeholder') public placeholder: any;

  //MAT-FORM-FIELD-LABEL
  @Input('label') public label: any;

  //OPTIONS FROM PARENT COMPONENT
  // @Input('options') public options: any[]; Not need for api options

  //OPTIONS URL FROM PARENT COMPONENT 
  @Input('api_endpoint') public api_endpoint: string;

  //PARAMS FROM THE PARENT COMPONENT
  @Input('api_params') public api_params: any;
  //RESPONSE KEY FROM PARENT COMPONENT
  @Input(`response_key`) public response_key: string;

  //ERROR MESSAGE FROM PARENT COMPONENT
  @Input('error_message') public error_message: any;

  //VALIDATORS REQUIRED [TRUE OR FALSE]
  @Input('required') public required: boolean;

  //VIEW VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input('options_display') public options_display: any;

  //VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input('options_value') public options_value: any;
  

  //ABSTRACT CONTROL OF THE PARENT FROMGROUP
  @Input('form_control') public form_control: AbstractControl;

  //EMITS VALUE WHEN A VALUE IS SELECTED 
  @Output('on_select') public on_select: EventEmitter<any> = new EventEmitter()
  /**********************************The above variables are for the PARENT COMPONENT*********************************************/

  public searchControl: FormControl;
  public filteredOptions: any[];
  public options: any[]=[]
  public load :boolean =false;
  constructor(private selectService: SelectboxService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.api_endpoint != '' && this.api_endpoint != undefined) {
      this.getdata()
    }
    if (changes.api_endpoint?.firstChange == false) {
      this.form_control.setValue('');
      this.form_control.updateValueAndValidity()
    }


  }
  ngOnInit(): void {
    this.UIapperance()
  }


  UIapperance() {
    this.searchControl = new FormControl('')
    //lable
    this.label = this.label ? this.label : 'Select-v2'

    //placeHolder 
    this.placeholder = this.placeholder ? this.placeholder : 'Search'
    //required
    switch (this.required) {
      case true: {
        this.form_control.setValidators([Validators.required])
        this.form_control.updateValueAndValidity()
        this.required = true
      }
        break
      default: {
        this.form_control.clearValidators()
        this.form_control.updateValueAndValidity()
        this.required = false
      }
        break
    }

  }




  ngAfterViewInit(): void {
    this.searchOptions()
  }

  searchOptions() {
    this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe((res: any) => {
      let value: string = (res)
      if (this.options?.length > 0 && this.options != undefined) {
        if (this.options_display != '' && this.options_value != '') {
          this.filteredOptions = this.options.filter((ele: any) => String(ele[this.options_display]).toLowerCase().includes(value.toLowerCase()))
        } else {
          this.filteredOptions = this.options.filter((ele: any) => String(ele).toLowerCase().includes(value.toLowerCase()))
        }
      }
    })

  }

  optionSelected(event: any) {
    let data: any = this.options.filter((ele: any) => checkNull(this.options_value) ? ele[this.options_value] == event?.value : ele == event?.value)
    this.on_select.emit(data[0]);
  }

  clearSearch() {
    this.searchControl.setValue('');
    this.filteredOptions = this.options
  }
  getdata() {
    this.load=true;
    this.selectService.getdata(this.api_endpoint, this.api_params).subscribe((res: any) => {
      this.load=false;
      if (res?.keyword == 'success') {
        this.options = res[this.response_key];
        this.filteredOptions = res[this.response_key];
      } else {
        this.options = [];
        this.filteredOptions = []
      }
    }, (err: any) => {
      this.load=false;
      this.options = [];
      this.filteredOptions = []
    })
  }

}
