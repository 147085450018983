import { SelectionChange } from '@angular/cdk/collections';
import { AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-single-select-staticoptions',
  templateUrl: './single-select-staticoptions.component.html',
  styleUrls: ['./single-select-staticoptions.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectStaticoptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SingleSelectStaticoptionsComponent),
      multi: true,
    },
  ],
})
export class SingleSelectStaticoptionsComponent implements OnInit, OnChanges, AfterViewInit {
  //SEARCH INPUT PLACEHOLDER
  @Input('placeholder') public placeholder: any;

  //MAT-FORM-FIELD-LABEL
  @Input('label') public label: any;

  //OPTIONS FROM PARENT COMPONENT
  @Input('options') public options: any[];

  //ERROR MESSAGE FROM PARENT COMPONENT
  @Input('error_message') public error_message: any;

  //VALIDATORS REQUIRED [TRUE OR FALSE]
  @Input('required') public required: boolean;

  //CONFIRMATION FROM PARENT WHETHER THE SELECTBOX IS MULTIPLE OR NOT
  @Input(`ifsingle`) private ifsingle: boolean = true

  //VIEW VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input('options_display') public options_display: any;

  //VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input('options_value') public options_value: any;

  //ABSTRACT CONTROL OF THE PARENT FROMGROUP
  @Input('form_control') public form_control: AbstractControl;

  //EMITS VALUE WHEN A VALUE IS SELECTED 
  @Output('on_select') public on_select: EventEmitter<any> = new EventEmitter()

  /**********************************The above variables are for the PARENT COMPONENT*********************************************/

  public searchControl: FormControl;
  public filteredOptions: any[] = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions = this.options;
    this.searchOptions()

  }

  ngOnInit(): void {
    this.UIapperance()
  }

  UIapperance() {
    this.searchControl = new FormControl('')
    //lable
    this.label = this.label ? this.label : 'Select-v2'

    //placeHolder 
    this.placeholder = this.placeholder ? this.placeholder : 'Search'
    //required
    switch (this.required) {
      case true: {
        this.form_control.setValidators([Validators.required])
        this.form_control.updateValueAndValidity()
        this.required = true
      }
        break
      default: {
        this.form_control.clearValidators()
        this.form_control.updateValueAndValidity()
        this.required = false
      }
        break
    }

  }




  ngAfterViewInit(): void {
    this.searchOptions()
  }

  searchOptions() {
    if (this.options?.length > 0 && this.options != undefined) {
      this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe((res: any) => {
        let value: string = (res);
        if (this.options?.length > 0 && this.options != undefined) {
          if (this.options_display != '' && this.options_value != '') {
            this.filteredOptions = this.options.filter((ele: any) => String(ele[this.options_display]).toLowerCase().includes(value.toLowerCase()));
          } else {
            this.filteredOptions = this.options.filter((ele: any) => String(ele).toLowerCase().includes(value.toLowerCase()))
          }
        }

      })
    }


  }

  optionSelected(event: any) {
    let data = this.options.find((ele: any) => (ele[this.options_value]) == event?.value)
    this.on_select.emit({ value: event?.value, data: data });
  }

  clearSearch() {
    this.searchControl.setValue('');
    this.filteredOptions = this.options
  }








}
