import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { DomSanitizer } from "@angular/platform-browser";
import { ChatSocketService } from "app/core/services/chat-socket.service";
import { checkNull, FormattedDate } from "app/core/services/date-and-time-validators.service";
import { SocketIoService } from "app/core/services/socket-io.service";
import { ToastService } from "app/core/services/toast.service";
import { VoiceRecorderService } from "app/core/services/voice-recorder.service";
import { EmployeeService } from "app/modules/admin/employee/services/employee.service";
import { TaskManagerService } from "app/modules/admin/task-manager/service/task-manager.service";
import { ChatDTO } from "./chat-constants";

let AudioPermission: boolean = false;
function getLocalStream() {
  navigator.mediaDevices
    .getUserMedia({ video: false, audio: true })
    .then((stream: any) => {
      AudioPermission = true;
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
      AudioPermission = false;
    });
}
declare var $: any;
const audioFormats: any[] = [
  `audio/mpeg`,
  "audio/wav",
  "audio/mp3",
  "audio/m4a",
];
const videoFormats: any[] = [`video/mp4`];
const imageFormats: any[] = [`image/jpeg`, `image/png`, "image/jpg"];
const documentFormats: any[] = [
  `application/pdf`,
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

function checkFile(type) {
  let audio = audioFormats.includes(type);
  let image = imageFormats.includes(type);
  let video = videoFormats.includes(type);
  let pdf = documentFormats.includes(type);

  return audio
    ? "audio"
    : video
      ? "video"
      : image
        ? "image"
        : pdf
          ? "pdf"
          : "text";
}
interface FILES {
  type: any;
  size: any;
  previewURL: any;
  name: any;
  file: any;
}

interface IMSG {
  fromUserId?: any;
  toUserId?: any;
  message?: any;
  order_items_id?: any;
  status?: any;
  created_at?: any;
  id?: any;
  file?: any;
  type?: any;
  isFile?: any;
}

interface PHPmsg {
  created_at?: any;
  customer_name?: any;
  delivered_at?: any;
  file?: any;
  fromUserId?: any;
  id?: any;
  is_deleted_fromUser?: any;
  is_deleted_toUser?: any;
  isread?: any;
  message?: any;
  online?: any;
  order_code?: any;
  order_items_id?: any;
  product_code?: any;
  profile_image?: any;
  profile_image_url?: any;
  read_at?: any;
  size?: any;
  soft_delete?: any;
  status?: any;
  time?: any;
  toUserId?: any;
  type?: any;
  path?: any;
}

@Component({
  selector: "app-chat-pop-up-admin",
  templateUrl: "./chat-pop-up-admin.component.html",
  styleUrls: ["./chat-pop-up-admin.component.css"],
})
export class ChatPopUpAdminComponent implements OnInit, OnChanges, AfterViewInit {
  isRecording = false;
  recordedTime;
  blobUrl;
  teste;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  // Developed variables
  formatsAllowed =
    "video/mp4,image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/csv";
  @Output("blobData") public blobData: EventEmitter<any> = new EventEmitter();
  @Output("closeChat") public closeChat: EventEmitter<any> = new EventEmitter();
  @Input("chatParams") public chatParams: any;
  @Input("chatDetails") public chatDetails: any;

  textBool: any = true;
  audioBool: any = false;
  attachmentBool: any = false;
  messageForm: FormGroup;
  recordedAudio: any = "";
  @ViewChild("messageInput") public messageInput: ElementRef;
  attachmentPreview: FILES;
  // Chat UI Variables
  conversation: IMSG[] = [];
  load: boolean = false;
  chatUserId: any;
  sampleAudio: string =
    "https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3";
  chatDTO: ChatDTO;
  chatUserData: any;
  disableInput: boolean;

  toggleBool: boolean = false;
  alertAudio = new Audio();
  isAvailableChat: boolean = false;
  constructor(
    private audioRecordingService: VoiceRecorderService,
    private sanitizer: DomSanitizer,
    private formbuilder: FormBuilder,
    private toaster: ToastService,
    private chatService: ChatSocketService,
    private socketService: SocketIoService,
    private modelService: EmployeeService,
    private taskManagerService: TaskManagerService
  ) {
    this.constructorAudio();
  }

  ngOnChanges(changes) {
    this.chatDTO = new ChatDTO();
    console.log(changes,'changes----->>>>')
    if (checkNull(this.chatParams?.employee_chat_user_id)) {
      this.chatDTO = new ChatDTO();
      this.chatDTO.toUserId = this.chatParams?.employee_chat_user_id;
      this.chatDTO.chat_user_id = this.chatParams?.chat_user_id;
      this.chatDTO.order_item_id = this.chatParams?.order_item_id;
      this.chatUserId = this.chatParams?.employee_chat_user_id;
      this.getConversationList();
    }
  }

  toggleChat() {
    this.toggleBool = this.toggleBool == true ? false : true;
    if (checkNull(this.chatParams?.employee_chat_user_id) && this.toggleBool) {
      this.chatDTO = new ChatDTO();
      this.chatDTO.toUserId = this.chatParams?.employee_chat_user_id;
      this.chatDTO.chat_user_id = this.chatParams?.chat_user_id;
      this.chatDTO.order_item_id = this.chatParams?.order_item_id;
      this.conversation = [];
      this.chatUserId = this.chatParams?.employee_chat_user_id;
      this.getConversationList();
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
  }


  getConversationList() {
    this.load = true;
    this.modelService.getConversationList(this.chatDTO).subscribe((res: any) => {
      this.load = false;
      this.chatDTO.totalpages = Math.ceil(res?.count / this.chatDTO.limit);
      let oldMessages: any[] = res?.data?.map((ele: PHPmsg) => {
        let obj: IMSG = {};
        obj.fromUserId = ele?.fromUserId ?? "";
        obj.toUserId = ele?.toUserId ?? "";
        obj.message = ele?.message ?? "";
        obj.created_at = ele?.created_at ?? "";
        obj.id = ele?.id;
        obj.file = ele?.path + ele?.message;
        obj.type = checkFile(ele.type);
        obj.isFile = checkNull(ele?.file);
        return obj;
      });
      oldMessages.reverse();
      this.conversation = [...oldMessages, ...this.conversation];
      if (this.chatDTO.offset == 0) {
        setTimeout(() => {
          $("#chat_conversation").animate(
            {
              scrollTop: $("#chat_conversation")[0].scrollHeight,
            },
            500
          );
        }, 500);
      } else {
      }
      // console.log(
      //     this.conversation,
      //     'this is the conversation',
      //     this.chatDTO
      // );
    });
  }

  downloadFile(data: any) {
    console.log(data);
    this.taskManagerService.singleFile(data?.message, 22)
  }

  receiveMessages() {
    this.socketService.receiveMessages().subscribe((res: any) => {
      let data = res?.data;
      let obj: IMSG = {};
      obj.fromUserId = data?.fromUserId;
      obj.id = data?.id ?? "";
      obj.isFile = checkFile(data?.type) != "text";
      obj.message = data?.message ?? "";
      obj.order_items_id = data?.order_items_id;
      obj.status = data?.status;
      obj.toUserId = data?.toUserId ?? "";
      obj.type = checkFile(data?.type) ?? "text";
      obj.file = res?.path + data?.message;
      let audio = document.getElementById("alertMsg") as HTMLAudioElement;
      audio.play();
      this.conversation.push(obj);
      $("#chat_conversation").animate(
        { scrollTop: $("#chat_conversation")[0].scrollHeight },
        1000
      );
    });
  }
  onScrollUp(event) {
    this.chatDTO.offset = this.chatDTO.offset + 1;
    if (this.chatDTO.offset < this.chatDTO.totalpages) {
      this.getConversationList();
    }
  }

  constructorAudio() {
    this.audioRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));
    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      if (time == "05:01") {
        this.stopRecording();
      }
      this.recordedTime = time;
    });
    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.teste = data;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
      // this.auSer.setSrc(this.blobUrl)
    });
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  download(): void {
    const url = window.URL.createObjectURL(this.teste.blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.teste.title;
    link.click();
  }

  toggleStyle: boolean = false;

  toggle() {
    this.toggleStyle = !this.toggleStyle;
  }
  close() {
    this.closeChat.emit();
  }

  checkByIndex(index: any) {
    if (index == 0) {
      return true
    } else {
      return (FormattedDate(this.conversation[index]?.created_at)) != (FormattedDate(this.conversation[index - 1]?.created_at))
    }
  }
}
