import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { checkNull } from "app/core/services/date-and-time-validators.service";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.css"],
})
export class AudioPlayerComponent implements OnInit, OnChanges {
  @Input("audioSrc") public audioSrc: any;
  @Input("type") public type: any;
  public id: any = Math.random();
  @Output("clearData") public clearData: EventEmitter<any> = new EventEmitter();
  duration: any;
  audio = new Audio();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (checkNull(this.audioSrc)) {
      this.audio = new Audio();
      this.audio.src = this.audioSrc;
      this.audio.load();
      this.duration = `0:0`;
    }
  }
  ngOnInit(): void { }

  checkNull(data): any {
    return checkNull(data);
  }

  hitGetDuration() {
    const audio = document.getElementById(
      `audioElement${this.id}`
    ) as HTMLAudioElement;

    setTimeout(() => {
      console.log("hitted------->", audio.paused);
      if (!audio.paused) {
        setInterval(() => {
          this.getDuration();
        }, 1000);
      }
    }, 200);
  }

  percentage: any = 0;
  getDuration() {
    const audio = document.getElementById(
      `audioElement${this.id}`
    ) as HTMLAudioElement;
    if (audio?.readyState) {
      this.duration = `${(audio.currentTime / 60).toFixed(0)}:${(
        audio.currentTime % 60
      ).toFixed(0)}`;
      // /${(audio.duration / 60).toFixed(0)}:${(audio.duration % 60).toFixed(0)}
      const duration = audio.duration;
      const currentTime = audio.currentTime;
      this.percentage = ((currentTime / duration) * 100).toFixed(0);
    } else {
      this.duration = `00.00`;
      this.percentage = 0;
      // /${(audio.duration / 60).toFixed(0)}:${(audio.duration % 60).toFixed(0)}
    }
  }

  seekDuration(value: any) {
    const audio = document.getElementById(
      `audioElement${this.id}`
    ) as HTMLAudioElement;
    audio.currentTime = (Number(value) / 100) * audio.duration;
    this.getDuration();
  }

  clearAudio() {
    this.clearData.emit();
  }
}
