<mat-form-field appearance="outline">
    <mat-label *ngIf="options">{{label|titlecase}}</mat-label>
    <mat-label *ngIf="options==undefined">
        <div class="select-placeholder-container">
            <span>{{label|titlecase}}</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
    </mat-label>
    <mat-select [formControl]="form_control" multiple="true" #select (selectionChange)="optionSelected($event)"
        (openedChange)="clearSearch()" >

        <div class="filter-input multiselect-filter" *ngIf="options?.length>1">
            <mat-checkbox (change)="selectAll($event)" [matTooltip]="'Select All'"
                *ngIf="need_all_options&&filteredOptions.length>0"
                [checked]="options?.length>0&&(form_control?.value?.length==options?.length)"></mat-checkbox>
            <!-- <div><mat-spinner ></mat-spinner></div> -->
            <input matInput #searchInput class="mat-filter-input" (blur)="valuesSelected()" [formControl]="searchControl"
                [placeholder]="placeholder" matInput type="search">
            <!-- <mat-spinner *ngIf="searchLoader" [diameter]="20" class="clear_icon"></mat-spinner> -->
            <mat-icon class="clear_icon" *ngIf="searchInput.value!=''" (click)="searchInput.value='';clearSearch()">
                cancel
            </mat-icon>
        </div>
        <div class="" style="margin-top: 10px;" *ngIf="options_display!=''&&options_value!=''">
            <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let data of filteredOptions"
                [value]="data[options_value]">
                {{data[options_display]}}
            </mat-option>
        </div>
        <div class="" style="margin-top: 10px;" *ngIf="options_display==''&&options_value==''">
            <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let data of filteredOptions"
                [value]="data">
                {{data}}
            </mat-option>
        </div>


        <mat-option class="no_data" *ngIf="filteredOptions?.length<=0||filteredOptions==undefined">No data
            found...</mat-option>
    </mat-select>
    <span matSuffix *ngIf="form_control.hasError('required') && form_control.touched" matTooltip="{{error_message }}"
        [matTooltipDisabled]="!form_control.invalid">
        <mat-icon color="warn">info</mat-icon>
    </span>
    <span matSuffix *ngIf="formgroup_errors?.NeedDistricts && form_control.touched"
        matTooltip="Please select the districts!" [matTooltipDisabled]="!formgroup_errors?.NeedDistricts">
        <mat-icon color="warn">info</mat-icon>
    </span>
</mat-form-field>