<div class="">
  <div class="float-button new_btn_9">
    <button (click)=" toggleChat()" mat-button class="new-ani-chat-butttom" #clickHoverMenuTrigger
      [matMenuTriggerFor]="menu">
      <mat-icon class="cht-ico oc"> chat</mat-icon>

      <span class="ani-text">Chat with Customer</span>
    </button>
  </div>
  <!-- [matMenuTriggerFor]="menu" -->

  <mat-menu #menu="matMenu" yPosition="above" backdropClass="chat">
    <mat-card class="p-0 m-0 chat-option">
      <mat-card-header>
        <mat-card-title>
          <div (click)="$event.stopPropagation()">
            <img [src]="chatDetails?.profile_image_url" alt="profile" class="avatar-image" />
            <span class="product-id">{{chatDetails?.customer_first_name}}-{{chatDetails?.order_code}}</span>
          </div>
          <span class="text-right">
            <mat-icon>close</mat-icon>
          </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="scroll pt-16" id="chat_conversation" infinite-scroll [scrollWindow]="false"
          [infiniteScrollDistance]="2" (scrolledUp)="onScrollUp($event)" [alwaysCallback]="true"
          (click)="$event.stopPropagation()">

          <!-- <h3 style="text-align:center" *ngIf="conversation?.length==0"> -->

          <div class="no_conversation" *ngIf="conversation?.length==0">


            <img src="assets/images/no_conver.png" alt="">

            <p><b>No Conversation Yet..</b></p>



          </div>

          <div class="" *ngFor="let data of conversation;let i =index">
            <div class="d-flex align-items-center justify-content-center mb-20" *ngIf="checkByIndex(i)">
              <h4 class="mb-0 chat_date">{{data?.created_at|date:'MMM d, y'}}</h4>
            </div>
            <!-- Employee  Message -->
            <div class="employee_chat" *ngIf="(data?.type=='text'||data?.type=='msg')&&data?.fromUserId==chatUserId">

              <label class="message1">
                {{data?.message}}
              </label>
              <p class="personemp">Employee - {{data?.created_at|date:'hh:mm a'}}</p>
            </div>

            <!-- Customer  Message -->
            <div class="mt-12" *ngIf="(data?.type=='text'||data?.type=='msg')&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <label class="message2">{{data?.message}}</label>
                <p class="personcus">Customer - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
            <!-- Customer Audio -->
            <div class="mt-12" *ngIf="data?.type=='audio'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <app-audio-player [type]="2" [audioSrc]="data?.file"></app-audio-player>
                </div>
                <p class="personcus">Customer - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
            <!-- Employee Audio -->
            <div class="mt-12" *ngIf="data?.type=='audio'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">

                <div class="message1">
                  <app-audio-player [type]="2" [audioSrc]="data?.file"></app-audio-player>
                </div>

                <p class="personemp">Employee - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer Video -->
            <div class="mt-12" *ngIf="data?.type=='video'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <div class="video">
                    <video width="160" height="100" controls>
                      <source [src]="data?.file" type="video/mp4">
                    </video>
                  </div>
                </div>
                <p class="personcus">Customer - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee Video -->
            <div class="mt-12" *ngIf="data?.type=='video'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1">
                  <div class="video">
                    <video width="160" height="100" controls>
                      <source [src]="data?.file" type="video/mp4">
                    </video>
                  </div>
                </div>
                <p class="personemp">Employee - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer Image -->
            <div class="mt-12" *ngIf="data?.type=='image'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <div class="img">
                    <a> <img height="160" width="160" [src]="data?.file" alt=""></a>

                  </div>
                </div>
                <p class="personcus">Customer - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee Image -->
            <div class="mt-12" *ngIf="data?.type=='image'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1">
                  <div class="img">
                    <a target="_blank" [href]="data-file"><img height="160" width="160" [src]="data?.file" alt=""></a>

                  </div>
                </div>
                <p class="personemp">Employee - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Customer File -->
            <div class="mt-12" *ngIf="data?.type=='pdf'&&data?.fromUserId!=chatUserId">
              <div class="customer_chat">
                <div class="message2">
                  <div class="audio">
                    <span><i class="fas fa-file"></i></span>
                    <label class="mb-0 ml-8 mr-8 text-trim">{{data.message}}</label>
                    <i class="fas fa-arrow-alt-circle-down" (click)="downloadFile(data)"></i>
                  </div>
                </div>
                <p class="personcus">Customer - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>

            <!-- Employee File -->
            <div class="mt-12" *ngIf="data?.type=='pdf'&&data?.fromUserId==chatUserId">
              <div class="employee_chat">
                <div class="message1">
                  <div class="audio">
                    <span><i class="fas fa-file"></i></span>
                    <label class="mb-0 ml-8 mr-8 text-trim">{{data.message}}</label>
                    <i class="fas fa-arrow-alt-circle-down" (click)="downloadFile(data)"></i>
                  </div>
                </div>
                <p class="personemp">Employee - {{data?.created_at|date:'hh:mm a'}}</p>
              </div>
            </div>
          </div>
        </div>






      </mat-card-content>

      <mat-card-actions (click)="$event.stopPropagation();">
        <!-- Text Message  -->
        <div class="" *ngIf="textBool">
          <mat-form-field appearance="none">
            <input matInput [disabled]="disableInput" type="text" id="message_sender"
              (keypress)="keyPressEvent($event,messageInput?.value,'text')" #messageInput
              placeholder="Type something here" />
          </mat-form-field>
          <input type="file" id="file_uploader" hidden #chatuploader (change)="fileChangeEvent($event)"
            [accept]="formatsAllowed">
          <button mat-button (click)="chatuploader.click()">
            <mat-icon>link</mat-icon>
          </button>
          <button mat-button (click)="changeMsgType('audio')">
            <mat-icon>settings_voice</mat-icon>
          </button>
          <!-- Loader -->
          <button mat-button *ngIf="msgload">
            <div class="spinner-border spinner text-secondary" role="status"></div>
          </button>
          <!-- send button -->
          <button mat-button *ngIf="!msgload" (click)="sendMessage(messageInput?.value,'text')">
            <mat-icon>send</mat-icon>
          </button>
        </div>


        <div>
          <!-- Audio -->
          <div class="px-10 d-flex align-items-center justify-content-between   option_sections" *ngIf="audioBool">


            <div class="record_animations mb-10 " *ngIf="isRecording && !blobUrl">
              <img src="https://icons8.com/vue-static/landings/animated-icons/icons/sound/sound_200.gif" height="30"
                width="50" alt="">

              <p class="mb-0"><Span class="red_dot"></Span>{{recordedTime}}</p>

              <!-- <span class="close_rec"><i class="fa fa-times" aria-hidden="true"></i></span> -->
            </div>

            <div *ngIf="isRecording && !blobUrl" (click)="stopRecording()">
              <span class="icons_margin"><i class="fa fa-stop" aria-hidden="true"></i></span>
            </div>

            <div class="position-relative audio_msg" *ngIf="!isRecording && blobUrl">
              <app-audio-player [type]="1" (clearData)="clearData()" [audioSrc]="blobUrl"></app-audio-player>
              <span class="close_rec" *ngIf="!msgload&&!load" (click)="clearData()"><i class="fa fa-times"
                  aria-hidden="true"></i></span>
              <div class="overlay" *ngIf="load">

                <div class="spinner-border text-light" role="status">

                </div>


              </div>
            </div>
            <button mat-button *ngIf="msgload||load">
              <div class="spinner-border spinner text-secondary" role="status"></div>
            </button>
            <div class="send_btn" *ngIf="!isRecording&&!msgload&&!load" (click)="sendFiles(2)">
              <mat-icon>send</mat-icon>
            </div>
          </div>
          <!-- Video -->
          <div *ngIf="attachmentBool&&attachmentPreview?.type=='video'"
            class="px-10 d-flex justify-content-between m-2  option_sections img_video">
            <div class="position-relative  mb-10">
              <a>
                <video [src]="attachmentPreview.previewURL" width="125" height="85" controls controlsList="nodownload">
                  <!-- <source type="video/mp4"> -->
                </video>
              </a>
              <span class="close_rec" *ngIf="!msgload&&!load" (click)="clearData()"><i class="fa fa-times"
                  aria-hidden="true"></i></span>

              <div class="overlay" *ngIf="load">

                <div class="spinner-border text-light" role="status">

                </div>


              </div>

            </div>
            <button mat-button *ngIf="msgload||load">
              <div class="spinner-border spinner text-secondary" role="status"></div>
            </button>
            <div class="send_btn" *ngIf="!msgload&&!load" (click)="sendFiles(1)">
              <mat-icon>send</mat-icon>
            </div>
          </div>
          <!-- Image -->
          <div *ngIf="attachmentBool&&attachmentPreview?.type=='image'"
            class="px-10 d-flex  justify-content-between m-2  option_sections img_video ">
            <div class="position-relative mb-10">
              <a>
                <img height="90" width="90" [src]="attachmentPreview.previewURL" alt="">
              </a>
              <span class="close_rec" *ngIf="!msgload&&!load" (click)="clearData()"><i class="fa fa-times"
                  aria-hidden="true"></i></span>
              <div class="overlay" *ngIf="load">

                <div class="spinner-border text-light" role="status">

                </div>


              </div>
            </div>
            <button mat-button *ngIf="msgload||load">
              <div class="spinner-border spinner text-secondary" role="status"></div>
            </button>
            <div class="send_btn" *ngIf="!msgload&&!load" (click)="sendFiles(1)">
              <mat-icon>send</mat-icon>
            </div>
          </div>
          <!-- PDF -->
          <div *ngIf="attachmentBool&&attachmentPreview?.type=='pdf'"
            class="px-10 d-flex  justify-content-between m-2  option_sections img_video ">
            <div class="position-relative mb-20">
              <div class="audio pdf">
                <span><i class="fas fa-file"></i></span>

                <label class="mb-0 ml-8 mr-8 text-trim">{{attachmentPreview?.name ?attachmentPreview?.name :'Document'
                  }}</label>

                <!-- <i class="fas fa-arrow-alt-circle-down"></i> -->
              </div>
              <span class="close_rec" *ngIf="!msgload&&!load" (click)="clearData()"><i class="fa fa-times"
                  aria-hidden="true"></i></span>
              <div class="overlay" *ngIf="load">

                <div class="spinner-border text-light" role="status">

                </div>


              </div>
            </div>
            <button mat-button *ngIf="msgload||load">
              <div class="spinner-border spinner text-secondary" role="status"></div>
            </button>
            <div class="send_btn" *ngIf="!msgload&&!load" (click)="sendFiles(1)">
              <mat-icon>send</mat-icon>
            </div>
          </div>
        </div>



      </mat-card-actions>
    </mat-card>
  </mat-menu>
</div>

<audio controls hidden id="alertMsg">
  <source src="/src/assets/audio/window_8.mp3">
</audio>