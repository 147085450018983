import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { checkNull } from "./date-and-time-validators.service";
import { decodedToken } from "../helpers/token.helper";

@Injectable({
    providedIn: "root"
})

export class GenerateRouteService {


    constructor(private route: Router) {

    }

    public queueCount = new ReplaySubject<any>(0);

    /** queue count */

    setQueueCount(property: any, storeProp: boolean = false) {
        if (storeProp) {
            localStorage.setItem('queueCount', JSON.stringify(property));
        }
        this.queueCount.next(property);
    }

    getQueueCount() {
        return this.queueCount;
    }

    navigateRouteMessage(portalName: any, page: any, idObj: any) {
        console.log(page , 'while routing........')
        switch (page?.page) {
            // Admin
            case "order_page":
            case "order_cancel":
            case "order_ready_for_dispatch":
                {
                    let url = (page?.url).split('?')[0];
                    this.route.navigate([url], { queryParams: page });
                }
                break;
            case "new_product":
                {
                    let url = ``;
                    let params: any = {}
                    switch (page?.service_id) {
                        // Passport
                        case 1:
                            url = `passport-size/view/${page?.product_id}`;
                            break;
                        //
                        case 2:
                            url = `photoprint/view/${page?.product_id}`;
                            break;
                        case 3:
                            url = `photo-frame/view?product=${page?.product_id}`;
                            break;
                        case 4:
                            url = `shop/personalized-product/view`;
                            params.product_id = page?.product_id;
                            break;
                        case 5:
                            url = `shop/e-commerce`;
                            params.product_id = page?.product_id;
                            console.log(url, 'url')
                            break;
                        case 6:
                            url = `shop/selfie-album/view`;
                            params.product_id = page?.product_id;
                            break;
                    }
                    this.route.navigate([url], { queryParams: params });
                }
                break;
            case "notify_me":
            case "out_of_stock":
                {
                    let url = ``;
                    let params: any = {}
                    switch (page?.service_id) {
                        // Passport
                        case 1:
                            url = `passportsize-photo/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            break;
                        //
                        case 2:
                            url = `photo-print/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            break;
                        case 3:
                            url = `Photoframe/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            break;
                        case 4:
                            url = `personalized-products/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            break;
                        case 5:
                            url = `e-commerce-product/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            console.log(url, params);

                            break;
                        case 6:
                            url = `selfie-album/view/${page?.product_id}`;
                            params.random_id = page?.random_id
                            break;
                    }
                    this.route.navigate([url], { queryParams: params });
                }
                break;

            case "new_product":
                {
                    let url = ``;
                    let params: any = {}
                    switch (page?.service_id) {
                        // Passport
                        case 1:
                            url = `passport-size/view/${page?.product_id}`;
                            break;
                        //
                        case 2:
                            url = `photoprint/view/${page?.product_id}`;
                            break;
                        case 3:
                            url = `photo-frame/view?product=${page?.product_id}`;
                            break;
                        case 4:
                            url = `shop/personalized-product/view`;
                            params.product = page?.product_id;
                            break;
                        case 5:
                            url = `shop/e-commerce`;
                            params.product = page?.product_id;
                            break;
                        case 6:
                            url = `shop/selfie-album/view`;
                            params.product = page?.product_id;
                            break;
                    }
                    this.route.navigate([url], { queryParams: params });
                }
                break;

            // Employee
            case "task_assign":
            case "customer_preview_rejection":
            case "customer_preview_approved":
            case "qc_approved":
            case "qc_rejected": {

                let params = {
                    taskManagerId: page?.task_manager_history_id ?? '',
                    type: checkNull(page?.order_items_id) ? 'order' : 'task'
                }
                this.route.navigate([`employee/employee-task-manger/employee-task-detail`], { queryParams: params })

            }
                break

            default: {
                if (decodedToken().portal == 'admin') {
                    this.route.navigate([`notification/list`]);
                } else {
                    this.route.navigate([`employee/notification/list`]);
                }
            }
                break
        }
    }
}